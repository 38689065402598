const EntitiesMenu = [
  {
    heading: 'menu',
    route: '/entities',
    pages: [
      // {
      //   heading: 'Entity Infos',
      //   route: '/entities/entity-infos',
      //   fontIcon: 'bi-dashboard',
      //   svgIcon: 'media/icons/duotune/general/gen025.svg',
      // },
      // {
      //   heading: 'Entity Info Details',
      //   route: '/entities/entity-info-details',
      //   fontIcon: 'bi-dashboard',
      //   svgIcon: '/media/icons/duotune/general/gen026.svg',
      // },
      {
        heading: 'Entity Types',
        route: '/entities/entity-types',
        fontIcon: 'bi-dashboard',
        svgIcon: '/media/icons/duotune/general/gen027.svg',
      },
      {
        heading: 'Industry Sector',
        route: '/entities/industry-sector',
        fontIcon: 'bi-dashboard',
        svgIcon: '/media/icons/duotune/general/gen026.svg',
      },
      // {
      //   heading: "Entity Type Roles",
      //   route: "/entities/entity-type-roles",
      //   fontIcon: "bi-dashboard",
      //   svgIcon: "/media/icons/duotune/general/gen027.svg",
      // },
      // {
      //   heading: "Entity Type Role Groups",
      //   route: "/entities/entity-type-role-groups",
      //   fontIcon: "bi-dashboard",
      //   svgIcon: "/media/icons/duotune/general/gen027.svg",
      // },
    ],
  },
];

export default EntitiesMenu;
